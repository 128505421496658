/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import SnowEditor from '../../src/components/standalone/SnowEditor';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    i: "i",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement("div", {
    style: {
      margin: '50px auto',
      maxWidth: 720
    }
  }, React.createElement(SnowEditor, null, new Array(200).fill(0).map((_, index) => {
    return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Heading ", React.createElement(_components.i, null, index)), React.createElement(_components.p, null, "List items:"), React.createElement(_components.ul, null, new Array(20).fill(0).map((_, index) => {
      return React.createElement(_components.li, null, "List ", React.createElement(_components.strong, null, "item"), " ", index);
    })));
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
